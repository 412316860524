import { post } from '@/http/http'
// 字典查询
export const sysGet = p => post('api/v1.0/sysconfig/sys_dict_get', p)

// 日志查询
export const logGet = p => post('api/v1.0/sysconfig/sys_log_get', p)

// 省份查询
export const sysProvince = p => post('api/v1.0/sysconfig/sys_province', p)

// 市查询
export const sysCity = p => post('api/v1.0/sysconfig/sys_city', p)

// 区县查询
export const sysAreas = p => post('api/v1.0/sysconfig/sys_areas', p)

// 街道查询
export const sysStreets = p => post('api/v1.0/sysconfig/sys_streets', p)

// 版本查询
export const versionGet = p => post('api/v1.0/sysconfig/sys_versioninfo_get', p)

// 版本添加
export const versionAdd = p => post('api/v1.0/sysconfig/sys_versioninfo_add', p)

// 版本删除
export const versionDelete = p => post('api/v1.0/sysconfig/sys_versioninfo_delete', p)

// 消息登陆后调用
export const mesLogin = p => post('api/v1.0/sysconfig/message_login', p)

// 消息登陆后调用
export const mesQuery = p => post('api/v1.0/sysconfig/message_get', p)

// 消息已读提交 - 全部
export const mesRead = p => post('api/v1.0/sysconfig/message_update', p)

// 消息已读提交 - 单条
export const msgIdRead = p => post('api/v1.0/sysconfig/message_update_id', p)
