import { post } from '@/http/http'

// 门店查询
export const storeListQuery = p => post('api/v1.0/shopinfo/shop_info_get', p)

// 门店下拉查询
export const storeDownQuery = p => post('api/v1.0/shopinfo/shop_drop_get', p)

// 门店信息添加
export const storeInfoAdd = p => post('api/v1.0/shopinfo/shop_info_add', p)

// 门店信息编辑
export const storeInfoEdit = p => post('api/v1.0/shopinfo/shop_info_update', p)

// 门店上下架
export const storeUpAndLow = p => post('api/v1.0/shopinfo/shop_shelves', p)

// 门店删除
export const storeInfoDel = p => post('api/v1.0/shopinfo/shop_info_delete', p)

// 门店认证添加
export const storeIdentAdd = p => post('api/v1.0/shopinfo/shop_auth_add', p)

// 门店认证编辑
export const storeIdentEdit = p => post('api/v1.0/shopinfo/shop_auth_update', p)

// 门店认证查询
export const storeIdentQuery = p => post('api/v1.0/shopinfo/shop_auth_get', p)

// 门店设置添加/编辑
export const storeSetAE = p => post('api/v1.0/shopinfo/shop_set_update', p)

// 门店设置查询
export const storeSetQuery = p => post('api/v1.0/shopinfo/shop_set_get', p)

// 美容师管理
// 美容师查询
export const cosmetQuery = p => post('api/v1.0/shopinfo/shop_beauty_get', p)

// 美容师编辑
export const cosmetEdit = p => post('api/v1.0/shopinfo/shop_beauty_update', p)

// 美容师上下线
export const cosmetLineEdit = p => post('api/v1.0/shopinfo/shop_enable', p)

// 美容师删除
export const cosmetDel = p => post('api/v1.0/shopinfo/shop_beauty_delete', p)

// 美容师业绩查询
export const achieveQuery = p => post('api/v1.0/shopinfo/shop_results_get', p)

// 美容师查询[分配美容师]
export const allocationQuery = p => post('api/v1.0/shopinfo/shop_beauty_all_get', p)
