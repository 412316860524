<template>
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="keyWord"  placeholder="请输入姓名/手机号" class="inputSearch"></el-input>
        <el-select v-model="keyStore" placeholder="全部门店" class="retSelect">
          <el-option v-for="item in storeList" :key="item.index" :label="item.shopname" :value="item.shopid"></el-option>
        </el-select>
        <el-select v-model="keyWork" placeholder="岗位" class="retSelect" style="width: 120px;">
          <el-option v-for="item in workList" :key="item.index" :label="item.sysvalue" :value="item.sysid"></el-option>
        </el-select>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>

      <div class="table_list">
        <el-table
          :data="cosmetList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="编号"
            prop="sbecode">
          </el-table-column>
          <el-table-column
            label="姓名"
            prop="sname">
          </el-table-column>
          <el-table-column
            label="手机号"
            prop="sphone">
          </el-table-column>
          <el-table-column
            label="性别"
            prop="ssex">
            <template slot-scope="scope">
              <span v-if="scope.row.ssex === 1">男</span>
              <span v-if="scope.row.ssex === 2">女</span>
            </template>
          </el-table-column>
          <el-table-column
            label="门店"
            prop="shopname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="部门"
            prop="depname">
          </el-table-column>
          <el-table-column
            label="岗位"
            prop="sysvalue">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="isuse">
            <template slot-scope="scope">
              <span v-if="scope.row.isuse === true">上线</span>
              <span v-if="scope.row.isuse === false">下线</span>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            label="操作">
            <template slot-scope="scope">
              <template v-if="roleBtn[1].power">
              <el-button type="text" size="small" @click="showEdit(scope.row)">编辑</el-button>
              </template>
              <template v-if="roleBtn[2].power">
                <el-button type="text" size="small" style="color: #44B557;" v-if="scope.row.isuse === false" @click="handPutAndOff(scope.row)">上线</el-button>
                <el-button type="text" size="small" style="color: red;" v-if="scope.row.isuse === true" @click="handPutAndOff(scope.row)">下线</el-button>
              </template>
              <template v-if="roleBtn[0].power">
                <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 美容师编辑 -->
    <div class="detailForm" v-if="showForm">
      <p class="formTitle">基本信息</p>
      <el-form :model="editForm" :rules="editFormRule" ref="cosmetForm">
        <div class="flexCB" style="margin-bottom: 22px;">
          <div class="detailFormItem">
            <p class="elFromLabel">美容师姓名</p>
            <p class="elFromVal">{{ editForm.sname }}</p>
          </div>
          <div class="detailFormItem">
            <p class="elFromLabel">性别</p>
            <p class="elFromVal">
              <span v-if="editForm.ssex === 2">女</span>
              <span v-if="editForm.ssex === 1">男</span>
            </p>
          </div>
        </div>
        <div class="flexCB" style="margin-bottom: 22px;">
          <div class="detailFormItem">
            <p class="elFromLabel">美容师手机</p>
            <p class="elFromVal">{{ editForm.sphone }}</p>
          </div>
          <div class="detailFormItem">
            <p class="elFromLabel">岗位</p>
            <p class="elFromVal">{{ editForm.sysvalue }}</p>
          </div>
        </div>
        <div class="flexCB" style="margin-bottom: 22px;">
          <div class="detailFormItem">
            <p class="elFromLabel">部门</p>
            <p class="elFromVal">{{ editForm.depname }}</p>
          </div>
          <div class="detailFormItem">
            <p class="elFromLabel">状态</p>
            <p class="elFromVal">
              <span v-if="editForm.isuse === true">已上线</span>
              <span v-if="editForm.isuse === false">已下线</span>
            </p>
          </div>
        </div>
        <div class="flexCB">
          <div class="detailFormItem" style="margin-bottom: 22px;">
            <p class="elFromLabel">门店</p>
            <p class="elFromVal">{{ editForm.shopname }}</p>
          </div>
          <el-form-item label="美容师编号" class="detailFormItem" prop="sbecode">
            <el-input v-model="editForm.sbecode"  placeholder="请输入美容师编号"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="从业年限" class="detailFormItem" prop="sbeyear">
            <el-input v-model="editForm.sbeyear"  placeholder="请输入从业年限"></el-input>
          </el-form-item>
          <el-form-item label="美容师等级" class="detailFormItem" prop="sbelevel">
            <el-select v-model="editForm.sbelevel" placeholder="请选择美容师等级" style="width: 100%;">
              <el-option v-for="item in sbelevelList" :key="item.index" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flexSC">
          <div class="detailFormItem">
            <el-form-item label="擅长项目(最多添加3个标签)" class="labelItem" prop="sbeject">
              <el-input v-model="cosmetTag"  placeholder="请输入擅长项目标签" style="width: calc(100% - 70px);"></el-input>
              <p class="zdyAddBtn" @click="tagAdd">添加</p>
            </el-form-item>
            <div class="flexAC" :class="editForm.sbeject.length === 0 ? '': 'tagBox'">
              <el-tag v-for="(tag,index) in editForm.sbeject" :key="tag" closable type="info" @close="tagDel(index)">{{tag}}</el-tag>
            </div>
          </div>
          <el-form-item label="美容师简介" class="detailFormItem" style="margin-bottom: 0px;">
            <el-input class="formTextarea" v-model="editForm.sbemark" type="textarea" maxlength="200" placeholder="请输入美容师简介(最多200字)" ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="美容师照片" class="detailFormItem showImg" prop="sbeother1">
          <template style="width: 100%;">
            <Upload :fileType="'jpg/png/jpeg'" :picture="editForm.furl" @uploadSuccess="infoImgSuccess"></Upload>
          </template>
        </el-form-item>
      </el-form>

      <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { cosmetQuery, storeDownQuery, cosmetEdit, cosmetLineEdit, cosmetDel } from '@/api/store'
import { sysGet } from '@/api/system'
import Upload from '@/components/upload.vue'
export default {
  name: 'storeCosmet',
  components: {
    Upload
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      keyWord: '',
      keyStore: '',
      storeList: [],
      keyWork: '',
      workList: [],
      cosmetList: [],
      showForm: false,
      editForm: {},
      editFormRule: {
        sbecode: [
          { required: true, trigger: 'blur', message: '编号不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        sbeyear: [
          { required: true, trigger: 'blur', message: '从业年限不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        sbelevel: { required: true, trigger: 'blur', message: '请选择美容师等级' },
        sbeject: { required: true, trigger: 'blur', message: '请添加美容师擅长项目' },
        sbeother1: { required: true, trigger: 'blur', message: '请上传美容师照片' }
      },
      sbelevelList: [
        { name: '一星', value: 1 }, { name: '二星', value: 2 }, { name: '三星', value: 3 },
        { name: '四星', value: 4 }, { name: '五星', value: 5 }
      ],
      cosmetTag: ''
    }
  },
  created () {
    this.init()
    this.getDown()
  },
  methods: {
    init () {
      const params = {
        shopid: this.keyStore,
        sname: this.keyWord,
        sjobs: this.keyWork,
        page_index: this.page,
        page_size: this.size
      }
      cosmetQuery(params).then(res => {
        if (res.status === 1) {
          this.cosmetList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    // 下拉框
    getDown () {
      storeDownQuery().then(res => {
        if (res.status === 1) {
          this.storeList = res.data.data
        }
      })
      sysGet({ systype: 'job' }).then(res => {
        if (res.status === 1) {
          this.workList = res.data.data
        }
      })
    },
    // 搜索
    handleSearch () {
      this.init()
    },
    // 重置
    handleReset () {
      this.keyStore = ''
      this.keyWord = ''
      this.keyWork = ''
      this.init()
    },
    // 翻页
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 展示【编辑】
    showEdit (row) {
      this.showForm = true
      this.editForm = { ...row }
      // console.log(this.editForm)
      if (row.sbeject !== '') {
        this.editForm.sbeject = row.sbeject.split(',')
      } else {
        this.editForm.sbeject = []
      }
    },
    tagAdd () {
      if (this.cosmetTag !== '') {
        if (this.editForm.sbeject.length < 3) {
          this.editForm.sbeject.push(this.cosmetTag)
          this.cosmetTag = ''
        }
      }
    },
    tagDel (eq) {
      this.editForm.sbeject.splice(eq, 1)
    },
    infoImgSuccess (res) {
      this.editForm.sbeother1 = res.fid
    },
    FromCancel () {
      this.showForm = false
    },
    // 美容师[编辑]提交
    FromConfirm () {
      this.$refs.cosmetForm.validate((valid) => {
        if (!valid) return
        const params = {
          sbeid: this.editForm.sbeid,
          sbecode: this.editForm.sbecode,
          sbeyear: this.editForm.sbeyear,
          sbeject: this.editForm.sbeject.join(','),
          sbemark: this.editForm.sbemark,
          sbelevel: this.editForm.sbelevel,
          sbeother1: this.editForm.sbeother1
        }
        cosmetEdit(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.showForm = false
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 美容师【上线-下线】
    handPutAndOff (row) {
      cosmetLineEdit({ staffid: row.staffid }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 美容师【删除】
    handDel (row) {
      this.$confirm('此操作将永久删除该美容师, 是否继续?', '删除美容师', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        cosmetDel({ sbeid: row.sbeid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.zdyAddBtn{
  width: 60px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #44B557;
  border-radius: 4px 4px 4px 4px;
  margin-left: 8px;
  color: #fff;
  cursor: pointer;
}
.labelItem{
  margin-bottom: 0px;
}
.labelItem >>> .el-form-item__content{
  width: 100%;
  display: flex;
}
.tagBox{
  padding-top: 10px;
  box-sizing: border-box;
}
.tagBox >>> .el-tag{
  margin-right: 20px;
}
.showImg >>> .el-form-item__label{
  width: 100%;
  text-align: left;
}
</style>
